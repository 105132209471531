@import "variables.less";
@import "slide.less";

// ESSE SITE FOI CRIADO EM UM DIA
// NÃO JULGUE MEU CÓDIGO
body{
  padding:0;
  margin:0;
  color:#424242;
  overflow-x: hidden;
  font-family: "lato", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
iframe{
  // height: 100%;
  min-height: 500px;
  width: 100%;
}
html, body {
  overflow-x:hidden 
} 
h1,
h2,
h3,
h4,
h5{
  font-family: Morebi, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
p{
  font-weight: lighter;
}


.intro{
  height: 100vh;
  @media (max-width: 768px){
    height:990px;
    .intro-img {
      margin-top: 8vh;
    }
  }
  P{
    font-family: lato;
  }
  background:linear-gradient(#1d84de,#4ab2aa);
  font-family: "morebi";
  overflow:hidden;
  header{
    // height:10vh;
    // border-bottom: 1px solid white
    padding:15px 0;
    #logo{
      width:200px;
    }
    nav{
      span{
        position: absolute;
        right: 50px;
        a{
          color:whitesmoke;
          margin-top:10px;
          margin-left:30px;
          // float:right;
        }
        @media (max-width: 768px){
          position: initial;
          a{
            display: inline-block;
            margin-top:30px;
            margin-right:30px;
            margin-left:0px;
          }
        }
      }
    }
  }
  .text{
    margin-top: 18vh;
    @media (max-width: 768px){
      margin-top: 9vh;
      margin-bottom: 5vh;
    }
    color:rgba(255, 255, 255, 0.95);
    h1{
      font-weight: 700;
    }
  }
  &-img{
    margin-top:10vh;
    overflow: hidden;
  }
}
.col-atributos-3{
  padding:30px 0 20px;
  background: #fafafa;
  .content{
    margin-top:35px;
    &::before{
      content:"";
      background: #1271d0;
      height:5px;
      display: block;
      margin-bottom: 8px;
    }
  }
  .col-md-4:nth-of-type(2) .content::before{
    background: #47b3a9;
  }
  .col-md-4:nth-of-type(3) .content::before{
    background: #cbdb32;
  }
  p,ul{
    color:#757575;
  }
  ul{
    padding:0;
  }
  li{
    padding-left:28px;
    list-style: none;
    background: url(../../dist/images/arrow.svg) no-repeat left center;
    background-size: 25px 10px;
    // list-style-image: url(../../dist/images/arrow.svg);
  }
}

.clientes{
  // min-height:15vh;
  padding:35px 0;
  padding-top:0; 
  .client-logo{
    display: block;
    margin:auto;
    margin-top:35px;
    height:100%;
    max-height: 90px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    width:90%;
    object-fit: contain;
    transition: .3s;
    &:hover{
      filter:none;
    }
  }
}
.telas{
  background:#f5f5f5;
  padding:50px 0;
  padding-bottom:10px;
  margin-bottom:-90px;
  & + *{
    padding-top:100px;
  }
  p{
    margin-bottom: 67px;
    color:#616161;
  }
  h2.header{
    color:#616161;
    margin-bottom: 0px;
  }
}
.overral{
  padding:50px 0;
  h2{
    @media (max-width: 768px){
      margin-bottom:15px;
    }
  }
  .list{
    margin-bottom:20px;
    @media (max-width: 768px){
      margin:30px 0;
    }
  }
  p{
    font-weight: normal
  }
  .clean-list{
    li{
      margin-bottom: 8px
    }
  }
}
.contato{
  // margin:5% 0%;
  margin-bottom: 0;
  padding:6%;
  background:#fdfdfd;
  @media (max-width: 768px){
    padding:26vh 0;
  }
  @media (min-width: 768px){
    .container>.row>div:nth-of-type(2){
    //  padding-top:100px
    }
  }
  a{
    margin-bottom: 6px;
    ion-icon{
      font-size:1.3em;
      line-height: 0px;
      margin-bottom:-0.25em;
      margin-right:2px;
    }
  }
  form{
    textarea{
      height: 100px
    }
    button{
      background: #1d84de;
      border-radius: 0;
      border:none;
      &:hover{
        background: #1db1de;
      }
    }
    .form-group{
      position: relative;

      label{
        will-change:tranform;
        position: absolute;
        top:10px;
        margin-left:15px;
        margin-top:6.5px;
        transition: 0.25s cubic-bezier(0, 0, 0.2, 1);
        background: white;
        border-radius:5px;
        padding:0px 7px;
        margin-left:10px;
      }
      textarea,
      input{
        // background:linear-gradient(20deg, #1d84de50,#4ab2aa50);
        border:none;
        border-bottom:1.5px solid #1dc1de;
        box-shadow: 0 0 4px #0000002e;
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.10);
        // border-bottom:2px solid linear-gradient(#1d84de,#4ab2aa);
        padding-top:7px;
        border-radius: 0px;
        margin-top:10px;
        &:valid + label,
        &:hover + label,
        &:active + label,
        &:focus + label{
          // font-size:.99rem;
          transform: translate(-5px, -20px)
        }

      }


      // textarea{
      //   font-size:.98rem;
      //   margin-top:10px;
      //   // &:empty  + label,
      //   &:valid + label,
      //   &:hover + label,
      //   &:active + label,
      //   &:focus + label{
      //     transform: translate(-5px, -20px)
      //   }
      // }

    }
  }


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


  p{
    font-weight: normal;
    margin-bottom: 10px
  }
  // .card{
  //   padding: 10vh;
  //   // color:white;
  //   max-width:800px;
  //   background:#47b3a9;
  //   margin:auto;
  //   a{
  //     // color:white;
  //   }
  //   .title{
  //     margin-bottom: 30px
  //   }
  // }
}
footer{
  background: whitesmoke;
  padding:30px 0;
  font-size: 1.2rem;
  font-weight: thin;
  color: rgba(0, 0, 0, 0.4);
  img.logo{
    height:15px;
    margin:20px auto;
    display: block;
  }
}

.glide__track{
  margin-top: -10px
}