.glide__slide{
  // padding:50px 100px;
  overflow: visible;
  height:600px;
  // background:black;
  color:white;
  position: relative;
  transition: .3s ease-out;
  opacity: .7;
  overflow: hidden;
  img{
    transition:width .3s cubic-bezier(0.39, 0.575, 0.565, 1), opacity .3s;
    width:85%;
    margin:auto;
    display: block;
  }
}
.glide__slides{
  overflow: visible;
}
.glide__track{
  position: relative;
  z-index: 1;
}
.glide__slide--active{
  opacity: 1;
  padding:10px;
  padding-top:0px;
  img{
    border-radius: 20px;
    width:100%;
  }
}
.glide__slide--clone{
}
.frame{
  position: absolute;
  top:0;
  left: 50%;
  margin-left:-200px;
  margin-top:-20px;
  width: 400px;
  pointer-events: none;
  z-index: 2;
}
#frame .frame-back{
  // display: block;
  // background: white;
  margin-top: -20px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width:100%;
  // background-clip: padding-box;
  // border:20px solid transparent;
  // border-width: 0 10px 0 10px;
  // border-radius: 42px;
  // box-shadow:0px 0px 10px #0000004d;
  -webkit-filter: drop-shadow(0px 0px 10px #0000004d);
  // filter: url(#drop-shadow);
  filter: drop-shadow(0px 0px 10px #0000004d);
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
}
.glide,#frame{
  position:relative
}
#frame{
  position: absolute;
  top:0;
  left: 50%;
  height: 600px;
  pointer-events: none;
  button{
    @media (max-width: 768px){
      display:none
    }
  }
}

.glide-control-left,
.glide-control-right{
  position: absolute;
  top:0;
  right:-85px;
  pointer-events: all;
  z-index: 200;
  top:50%;
  margin-top:-53px/2;
  // border-radius: 50%;
  border:none;
  background:none;
  // line-height: 8px;
  // padding:20px;
  outline: none;
  img{
    height:53px;
  }
  &:focus{
    outline:none
  }
}
.glide-control-left{
  left:-85px
}


// MIND GAME
.glide__slide{
  transform: translateX(-90px);
}
.glide__slide--active{
  transform: translateX(0px);
}
.glide__slide--active ~ .glide__slide{
  transform: translateX(90px);
}