@font-face {
  font-family: 'Morebi Stencil';
  src: url('/dist/fonts/') format('woff2'),
      url('/dist/fonts/MorebiRounded-ItalicStencil.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-ItalicStencil.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Bold.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Bold.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-BoldItalic.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-BoldItalic.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi Medium Stencil';
  src: url('/dist/fonts/MorebiRounded-MediumStencil.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-MediumStencil.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-MediumStencil.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Black.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Black.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Medium.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Medium.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Thin.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Thin.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi Stencil';
  src: url('/dist/fonts/MorebiRounded-Stencil.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Stencil.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Stencil.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Italic.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Italic.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-LightItalic.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-LightItalic.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-ThinItalic.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-ThinItalic.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Regular.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Regular.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-Light.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-Light.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-MediumItalic.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-MediumItalic.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi';
  src: url('/dist/fonts/MorebiRounded-BlackItalic.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-BlackItalic.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-BlackItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Morebi Medium Stencil';
  src: url('/dist/fonts/MorebiRounded-MediumItalicStencil.woff2') format('woff2'),
      url('/dist/fonts/MorebiRounded-MediumItalicStencil.woff') format('woff'),
      url('/dist/fonts/MorebiRounded-MediumItalicStencil.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

