
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');



@import "fonts.less";


// Required Core Stylesheet
@import "../../dist/css/glide/glide.core.css";

// Optional Theme Stylesheet
@import "../../dist/css/glide/glide.theme.css";






// UTILS
.clean-list{
  padding:0;
  li{
    padding-left:28px;
    list-style: none;
    background: url(../../dist/images/arrow.svg) no-repeat left center;
    background-size: 25px 10px;
  }
}